main {
  min-height: calc(100dvh - 60.71px);
}
.height-100 {
  min-height: calc(100dvh - 60.71px);
}

button.btn-primary {
  border-radius: 0;
  background: #b02a37;
  border: none;
}
button.btn-primary:hover, button.btn-primary:active {
  background: #7b151f !important;
}

button.btn-add-user {
  bottom: calc(100% + 10px);
  width: 60px;
  height: 60px;
}

.add-user {
  top: 60.71px !important;
}

.camera-position img {
  z-index: 100;
}

.btn-picture {
  width: 60px;
  height: 60px;
  right: 10px !important;
  z-index: 10;
}
.btn-back {
  width: 40px;
  height: 40px;
  right: 10px !important;
  top: 10px !important;
  z-index: 10;
}